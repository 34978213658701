.PastServersSec {
    text-align: center;
}

.PastServers-sp-br {
    display: none;
}

.PastServers {
    max-height: 400px;
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    user-select: none;
    scroll-snap-type: x mandatory;
}

.PastServers::-webkit-scrollbar {
    height: 7px;
    /* スクロールバーの高さ */
}

.PastServers::-webkit-scrollbar-thumb {
    background: #bebebe;
    /* ツマミの色 */
    border-radius: 5px;
    /* ツマミ両端の丸み */
}

.PastServers::-webkit-scrollbar-track {
    background: #313339;
    /* トラックの色 */
    border-radius: 5px;
    /* トラック両端の丸み */
}

.PastServers div p {
    text-align: center;
    padding: 0 0 10px 0;
}

.PastServers div img {
    scroll-snap-align: center;
    margin: 25px;
    width: 550px;
    height: auto;
    cursor: pointer;
    border-radius: 5%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.58);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content iframe {
    border-radius: 1%;
    width: 715px;
    height: 400px;
}

@media screen and (max-width: 959px) {
    .PastServers-sp-br {
        display: block;
    }

    .PastServers div img {
        width: 380px;
        height: 220px;
        margin: 5px;
    }

    .modal-content iframe {
        width: 360px;
        height: 200px;
    }
}