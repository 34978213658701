/*** サイトポリシー ***/
.rules {
    text-align: center;
    padding: 0 10px 50px 10px;
}

.page-crumb {
    padding-top: 0;
}

.rules h1 {
    font-size: 3.0rem;
    text-align: center;
    padding: 70px;
}

.rules-text {
    display: inline-block;
    text-align: left;
    max-width: 45%;
}

.rules-text a {
    color: #000;
    transition: all 0.3s;
}

.rules-text a:hover {
    opacity: 0.6;
}

.rules-text p {
    padding: 5px;
    text-wrap: balance;
    word-break: auto-phrase;
}

.rules-text h2 {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 1.5rem;
}

.rules-text ul {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
}

.rules-text ul li {
    text-wrap: balance;
    word-break: auto-phrase;
}

@media screen and (max-width: 959px) {
    .rules h1 {
        padding: 30px 0;
    }

    .rules-text {
        max-width: 100%;
    }
}