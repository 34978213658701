/*** 記事読み込みアニメーション ***/
#NewsLoading {
    width: 210px;
    height: 30vh;
    margin: 0 auto;
    padding-top: 70px;
}

#NewsLoading img {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: auto;
}

#NewsLoading p {
    text-align: center;
}

/*** ニュース ***/
.news-sec {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
}

.news {
    width: 400px;
    background-color: white;
    border: 1px solid #000;
    border-radius: 20px;
    margin: 20px;
}

.news img {
    width: 100%;
    height: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.news-text {
    padding: 5px 20px;
}

.news h1 {
    padding-top: 10px;
    font-size: 20px;
}

.news-date {
    padding-bottom: 20px;
    opacity: .6;
}

.news-content ul,
.news-content ol {
    padding-left: 30px;
}

.news-ReadMore {
    padding: 50px 0;
}

.NewsTextAD {
padding: 10px;
}

@media screen and (max-width: 959px) {
}
