.LatestVideosPosts {
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
    overflow: auto;
}

.LatestVideosPosts::-webkit-scrollbar {
    height: 7px;
    /* スクロールバーの高さ */
}

.LatestVideosPosts::-webkit-scrollbar-thumb {
    background: #bebebe;
    /* ツマミの色 */
    border-radius: 5px;
    /* ツマミ両端の丸み */
}

.LatestVideosPosts::-webkit-scrollbar-track {
    background: #313339;
    /* トラックの色 */
    border-radius: 5px;
    /* トラック両端の丸み */
}

.twitter-timeline-container div {
    margin: 0 auto;
}

.twitter-timeline {
    margin: 0 auto;
}

@media screen and (max-width: 959px) {
    .LatestVideosPosts {
        display: block;
    }

    .LatestVideosPosts div iframe {
        width: 390px;
        height: 230px;
        padding-bottom: 30px;
    }
}