* {
  margin: 0;
  padding: 0;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  user-select: none;
}

html {
  background-image: url(./img/img/PaperTexture.jpg);
}

@import url('https://fonts.googleapis.com/css2?family=Zen+Kurenaido&display=swap');

body {
  font-family: 'Zen Kurenaido', sans-serif;
}

button {
  font-family: 'Zen Kurenaido', sans-serif;
}

html {
  scroll-behavior: smooth;
}


.aLINK {
  padding: 0 5px 0 5px;
  font-size: 15px;
  color: #5b9fff;
}



blockquote {
  position: relative;
  padding: 10px 15px 10px 60px;
  box-sizing: border-box;
  font-style: italic;
  background: #f5f5f5;
  color: #777777;
  border-left: 4px solid #9dd4ff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.14);
  ;
}

blockquote:before {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  content: "\f10d";
  font-family: FontAwesome;
  color: #9dd4ff;
  font-size: 30px;
  line-height: 1;
  font-weight: 900;
}

blockquote p {
  padding: 0;
  margin: 7px 0 7px 50px;
  line-height: 1.7;
}

blockquote cite {
  display: block;
  text-align: right;
  color: #888888;
  font-size: 0.9em;
}


.text-align {
  text-align: center;
}

.comp {
  padding: 55px 0 10px;
}

.section {
  height: 90vh;
  display: table;
  width: 100%;
}

.sec-div {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.sec-title {
  font-size: 50px;
  text-align: center;
}

.newTab {
  width: 15px;
}

/* ========== パンくずリスト ========== */
.breadcrumb {
  padding: 50px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  user-select: none;
}

.breadcrumb a {
  color: black;
}

.breadcrumb a:hover {
  color: #006aff;
}

.breadcrumb li:not(:last-of-type)::after {
  content: "›";
  margin: 0 .6em;
  /* 記号の左右の余白 */
  color: #777;
  /* 記号の色 */
}


/*** ヘッター***/

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background: #fff;
  color: #000;
  height: 60px;
  position: fixed;
  z-index: 10;
  box-shadow: 0px 1px 20px 0px #333;
  padding: 10px;
}

.default-header-logo {
  width: 150px;
  height: auto;
  user-select: none;
}

.kawaii-header-logo {
  width: 70px;
  height: auto;
  user-select: none;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin: 0 15px;
  user-select: none;
}

nav ul li a {
  font-size: 1rem;
  text-decoration: none;
  color: var(--white-color);
  transition: all 0.2s;
}

nav ul li a:hover {
  color: #48b4af;
}


/* 
ページトップ
*/
.top-mov {
  padding: 0 0;
  position: relative;
}

.top-mov img {
  width: 100%;
  height: auto;
}

.top-mov video {
  top: 0;
  left: 0;
  width: 100%;
  height: 95vh;
  object-fit: cover;
}

.top-mov-img {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20% !important;
  height: auto;
}

.top-mov-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  font-size: 30px;
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 0 #000;
  cursor: default;
  user-select: none;
}

.top-mov-small {
  text-align: right;
}


/* 
アバウト
*/
.about {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.aboutIMG img {
  width: 600px;
  height: 100%;
  filter: drop-shadow(5px 10px 10px #606060);
}

.about ul h2 {
  font-size: 30px;
}

.about ul li,
.about ul li a {
  list-style: none;
  color: #000;
  margin: 15px 0;
  transition: all 0.3s;
}

.about ul li a:hover {
  opacity: 0.6;
}

.about .cont-but {
  padding-top: 30px;
  text-align: center;
}

.about .cont-but a {
  text-decoration: none;
  color: #000;
  padding: 10px 15px;
  border: 1px solid #000;
  transition: all 0.5s;
}

.about .cont-but a:hover {
  opacity: 0.7;
  box-shadow: 5px 5px 0px 0px #333;
}


/* 
参加方法
*/
.join-youtube {
  justify-content: space-around;
  /* 要素の中央揃え */
  display: flex;
}

.join h2 {
  list-style: none;
  text-align: center;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.youtube {
  display: grid;
  align-content: center;
  justify-content: center;
  padding-left: 20px;
}

.youtube small {
  text-align: right;
}

.flow03 li dl dd a {
  font-size: 1.28rem;
  color: #000;
  transition: all 0.3s;
}

.flow03 li dl dd a:hover {
  opacity: 0.6;
}

.flow_design03 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.flow_design03 ul {
  padding: 0;
}

.flow_design03 li {
  list-style-type: none;
  text-wrap: balance;
  word-break: auto-phrase;
}

.flow_design03 dd {
  text-align: left;
  margin-left: 0;
}

.flow03 {
  position: relative;
}

.flow03::before {
  content: "";
  width: 15px;
  height: 100%;
  background: #9e9e9e;
  margin-left: -129px;
  display: block;
  position: absolute;
  top: 0;
  left: 120px;
  border-radius: 20px;
}

.flow03>li {
  position: relative;
}

.flow03>li:not(:last-child) {
  margin-bottom: 60px;
}

.flow03>li .icon03 {
  font-size: 0.8em;
  width: 2em;
  height: 2em;
  line-height: 2;
  text-align: center;
  font-weight: bold;
  border-radius: 100vh;
  color: #fff;
  background: #8EB83C;
  display: inline-block;
  margin-right: 0.3em;
}

.flow03>li dl {
  padding-left: 70px;
  position: relative;
}

.flow03>li dl::before,
.flow03>li dl::after {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
}

.flow03>li dl::before {
  width: 7px;
  height: 7px;
  margin-top: -3px;
  background: #a2d145;
  border-radius: 50%;
  left: -4px;
}

.flow03>li dl::after {
  width: 50px;
  border-bottom: 1px dashed #999;
  position: absolute;
  left: 5px;
}

.flow03>li dl dt {
  font-size: 1.3em;
  font-weight: 600;
  color: #8EB83C;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
}


@media screen and (max-width: 959px) {
  .sec-div {
    width: 100%;
  }

  .comp {
    padding: 80px 0 0;
  }

  .breadcrumb {
    padding: 20px 20px 10px 20px;
  }

  nav ul {
    padding-left: 20px;
    text-align: center;
  }

  nav ul li {
    padding: 10px 0;
  }

  nav ul li a {
    font-size: 0.9rem;
  }

  .top-mov-img {
    width: 60% !important;
    height: auto;
  }

  .about {
    display: grid;
  }

  .aboutIMG {
    display: grid;
    justify-content: center;
  }

  .aboutIMG img {
    width: 100%;
    height: auto;
  }

  .about ul {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 10px;
  }

  .about ul h2 {
    text-align: center;
  }

  .join-youtube {
    display: block;
  }

  .youtube {
    padding-top: 50px;
    padding-left: 0;
  }

  .youtube iframe {
    width: 390px;
    height: 230px;
  }

  .form iframe {
    height: 1300px;
  }

}