.searchBar {
    display: grid;
    text-align: right;
    padding-right: 20px;
    justify-content: end;

    position: relative;
}

.searchContents {
    display: flex;
    flex-flow: column;
    list-style: none;
    align-items: flex-end;

    position: absolute;
    /* 検索結果を絶対位置で表示 */
    top: 100%;
    /* 検索バーの下に表示 */
    left: 0;
    right: 0;
    z-index: 2;
    /* 他の要素の上に表示 */
    list-style: none;
    /* リストスタイルを非表示 */
    padding: 0 20px 0 0;
    margin: 0;
}

.searchContentsLi {
    text-align: left;
    padding: 5px 10px;
    background-color: #ffffff;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    width: 227px;
}

.searchContentsLi:last-child {
    border-bottom: 1px solid #cccccc;
    border-bottom-left-radius: .5em;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5em;
    border-bottom-right-radius: .5rem;
}

.CanNotSearch {
    font-size: .8em;
    background-color: #ffffff;
    padding: 10px 5px 10px 5px;
    text-align: center;
    width: 237px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-bottom-left-radius: .5em;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5em;
    border-bottom-right-radius: .5rem;
}

.searchForm input {
    font: 400 16px/1;
    font-size: 16px;
    padding: .75em .25em .75em 3em;
    border-top-left-radius: .5em;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5em;
    border-top-right-radius: .5rem;
    border: 1px solid transparent;
    background: no-repeat left .8em center / 1.5em url(../img/svg/MagnifyingGlass.svg);
    cursor: pointer;
    cursor: auto;
    border-color: #ccc;
    background-color: #ffffff;
}