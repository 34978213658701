/* フッター*/
.foot-ul {
    display: flex;
    justify-content: space-around;
    border: 1px solid #000;
    background-color: #eeefef;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.page-up {
    margin: 0 30px;
    text-align: right;
}

.page-up button {
    display: inline-block;
    cursor: pointer;
    border: none;
}

.page-up button:hover {
    opacity: .6;
}

.page-up button img {
    width: 30px;
    height: auto;
    transform: scaleX(-1);
    background-color: #eef0ef;
    border: 1px solid #000;
    padding: 15px;
    border-radius: 10px;
}

.foot-ul div {
    display: flex;
    padding-bottom: 30px;
}

.foot-ul div ul {
    list-style: none;
    user-select: none;
    padding: 30px;
}

.foot-ul div ul p {
    border-bottom: 3px solid #777;
    margin-bottom: 15px;
}

.foot-ul div ul li {
    padding: 0 10px 0 15px;
    margin: 10px 0;
}

.foot-ul div ul li a {
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid #999;
}

.footerQR {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    max-width: 170px;
}

.footerQR img {
    display: grid;
    width: 150px;
    height: 150px;
    user-select: none;
    margin: 0 auto;
}

#qr-share {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    border-bottom: 1px solid #999;
    user-select: none;
}

#qr-share:hover {
    color: #0051c3;
    border-bottom: 1px solid #0051c3;
}

.foot-ul div ul li a:hover {
    color: #0051c3;
    border-bottom: 1px solid #0051c3;
}

.sns {
    background-color: #dcdcdc;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    padding: 20px 20px 0 20px;
    user-select: none;
}

.sns h3 {
    padding: 0 15px;
}

.sns ul {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-bottom: 30px;
    align-items: center;
}

.sns ul li {
    padding: 0 10px;
}

.sns ul li a {
    text-decoration: none;
}

.sns ul li a:hover {
    color: #006aff;
}

.footer-kawaiiErr {
    text-align: right;
}

.footer-kawaiiErr a {
    padding-left: 5px;
    text-decoration: none;
    color: #000;
}

.footer-kawaiiErr a:hover {
    color: #0051c3;
    text-decoration: underline;
}

.copy-btu button:hover {
    color: #0051c3;
    text-decoration: underline;
}

.sns ul li a img,
.copy-btu button img {
    width: 30px;
    height: auto;
}

.sns ul li a small {
    color: #000;
}

.sns ul li a small:hover {
    color: #0059d4;
    text-decoration: underline;
}

.twitter-share-button {
    margin: auto 0;
}

.copy-btu button {
    background-color: #dcdcdc;
    color: #000;
    border: none;
    cursor: pointer;
    width: 130px;
}

.footer h4 {
    text-align: center;
    background-color: #4b4947;
    border: 1px solid #000;
    color: #fff;
    padding: 15px;
    padding-right: 20px;
    font-size: 12px;
    user-select: none;
}

@media screen and (max-width: 959px) {
    .foot-ul {
        display: block;
    }

    .foot-ul div {
        display: block;
        padding: 0;
        padding-bottom: 30px;
        padding-top: 20px;
    }

    .foot-ul div ul {
        max-width: 250px;
        margin: 0 auto;
    }

    .foot-ul div ul p {
        max-width: 200px;
        margin-top: 20px;
    }

    .footerQR {
        margin: 0 auto;
    }
}