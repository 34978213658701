.news-note {
    background-color: #fff;
    width: 850px;
    margin: 50px auto;
}


/***　ルーズリーフ風ボックス　***/
/*外枠の設定*/
.note-box {
    border: solid 2px #999;
    /*外枠の線*/
    padding: .5em 1em 1em 2em;
    /*内側の余白*/
    position: relative;
    width: 700px;
    margin: 50px auto;
    box-shadow: 0px 0px 10px 0px #646464;
}

@media screen and (max-width: 959px) {
    .note-box {
        width: 70%;
    }
}
