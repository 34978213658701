* {
    margin: 0;
    padding: 0;
}

.err {
    padding: 100px 200px 100px 200px;
}

.Err-png-div {
    margin: 0 auto;
    width: 500px;
}

.Err-png {
    width: 100%;
    height: auto;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    user-select: none;
}

.err p,
.err-home {
    text-align: center;
}

.err-home {
    padding: 50px;
}

.err-home a {
    text-decoration: none;
    padding: 15px 20px;
    color: #000;
    border: 1px solid #000;
    transition: all 0.5s;
}

.err-home a:hover {
    opacity: 0.7;
    box-shadow: 5px 5px 0px 0px #333;
}


.LatestVideosPosts {
    padding-bottom: 50px;
}


@media screen and (max-width: 959px) {
    .Err-png-div {
        margin: 0 auto;
        width: 100%;
    }

    .err {
        padding: 50px 10px 100px 10px;
    }
}