.header {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.menu-sp {
    display: none;
}

.hamburger-button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
    background-color: #333;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: all 0.3s cubic-bezier(0.18, 0.92, 0.53, 0.87);
    width: 100%;
}

.hamburger-icon::before {
    top: -15px;
}

.hamburger-icon::after {
    top: 15px;
}

.menu {
    background-color: #fff;
    display: none;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
}

.menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0 10px 15px #606060;
}

.menu li a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
}

.menu li a:hover {
    background-color: #f5f5f5;
}

.header.open .menu {
    display: block;
}

.header.open .hamburger-icon {
    background-color: transparent;
}

.header.open .hamburger-icon::before,
.header.open .hamburger-icon::after {
    top: 0;
}

.header.open .hamburger-icon::before {
    transform: rotate(45deg);
}

.header.open .hamburger-icon::after {
    transform: rotate(-45deg);
}

@media screen and (max-width: 580px) {
    .menu-sp {
        display: block;
        flex-wrap: wrap;
    }

    .menu-pc {
        display: none;
    }
}