.gallery-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0 10px 0;
    max-width: 100%;
}

.gallery-img {
    width: 350px;
    height: 200px;
    background-size: cover;
    background-position: center;
    position: relative;
    margin: 15px;
    border-radius: 10px;
    cursor: zoom-in;
    transition: all .5s;
}

.gallery-img img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all .5s;
}

.gallery-title {
    position: absolute;
    top: 80%;
    left: 2%;
    font-size: 20px;
    color: #fff;
    opacity: 0;
    margin: 0;
    transition: all .5s;
}

.gallery-img:hover {
    background-color: #5e5e5e;
}

.gallery-img:hover img {
    opacity: 0.5;
}

.gallery-img:hover p {
    opacity: 1;
}


.modal {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 20px;
}

.modal-content .close {
    font-size: 25px;
    z-index: 999;
    cursor: pointer;
    display: block;
}

.modal-content img {
    width: 500px;
    height: auto;
    border-radius: 10px;
    filter: drop-shadow(5px 10px 10px #606060);
    margin: 20px;
}

.modal-text {
    margin: 0 20px 0 20px;
    max-width: 500px;
}

.modal-title {
    font-size: 20px;
}


.modal-cat-date {
    display: flex;
    padding-bottom: 10px;
}

.gallery-category {
    opacity: .7;
    color: #6868ff;
}

.gallery-category i {
    padding-right: 2px;
}

.modal-category {
    opacity: .7;
    color: #6868ff;
}

.modal-category i {
    padding-right: 2px;
}

.modal-date {
    opacity: .7;
    color: rgb(37, 37, 37);
    padding-left: 15px;
}

.modal-date i {
    padding-right: 5px;
}

.modal-name {
    opacity: .7;
    color: rgb(37, 37, 37);
    padding-left: 15px;
}

.modal-name i {
    padding-right: 5px;
}

.modal-category-span,
.modal-date span,
.modal-name span{
    font-size: .8rem;
}






.modal {
    display: none;
    /* Initially hidden */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    /* Add transition */
    opacity: 0;
    transition: opacity 0.5s ease;
    padding-top: 40px;
}

/* Styles for showing the modal */
.modal.show {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    /* Set opacity to 1 for a smooth transition */
}

.modal-content {
    background-color: rgb(254, 254, 254);
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: auto;
    max-height: 500px;
}

.modal-content::-webkit-scrollbar {
    width: 16px;
}

.modal-content::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 8px;
    border-right: 4px solid transparent;
    /* 透明なボーダーをつける */
    border-left: 4px solid transparent;
    /* 透明なボーダーをつける */
    background-clip: padding-box;
    /* 背景を切り取る */
}

.modal-content::-webkit-scrollbar-track {
    /* 上下に余白を付ける */
    margin-top: 4px;
    margin-bottom: 4px;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.category {
    text-align: center;
}



.SelectiveTagClass {
    background-color: #dcdcdc;
}

.category-buttons {
    text-align: center;
    margin: 10px;
}

.category-buttons button {
    padding: 10px 30px 10px 30px;
    margin: 10px;
}

.category-delete-buttons {
    text-align: center;
}



@media only screen and (max-width: 768px) {
    .gallery-div {
        width: 500px;
    }

    .gallery-img {
        /* スマホ表示時のサイズ調整等 */
        height: auto;
        /* 高さを自動調整する場合 */
    }

    .modal-content img {
        width: 90%;
        /* モーダル内の画像幅を調整 */
    }

    .modal-content,
    .modal-text {
        max-width: 90%;
        /* モーダルとテキストの最大幅を調整 */
    }
}