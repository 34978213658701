.news-text-sec {
    padding-bottom: 50px;
}

.NewsText {
    margin: 50px 200px;
    background-color: #fff;
}

.NewsText ol {
    padding-left: 30px;
}

.NewsText .news-date {
    padding: 10px 0 30px 0;
}

.news-p-content p,
.news-p-content h1,
.news-p-content h2,
.news-p-content h3,
.news-p-content h4,
.news-p-content h5,
.news-p-content blockquote,
.news-p-content pre,
.news-p-content ul li,
.news-p-content ol li {
    padding: 0.5em 0 0.5em 0em;
}

.news-look {
    text-align: center;
}

.news-look a {
    text-decoration: none;
    padding: 15px 20px;
    color: #000;
    border: 1px solid #000;
    transition: all 0.5s;
}

.news-look a:hover {
    opacity: 0.7;
    box-shadow: 5px 5px 0px 0px #333;
}


@media screen and (max-width: 959px) {
    .NewsText {
        margin: 20px 10px 50px 10px;
    }
}