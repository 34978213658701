.newscontact-news-more {
    text-align: center;
}

.newscontact-news-supplement {
    text-align: center;
}

.newscontact-news div a {
    text-decoration: none;
    color: #000;
    padding: 10px;
    border: 1px solid #000;
    transition: all 0.5s;
}

.newscontact-news div a:hover {
    opacity: 0.7;
    box-shadow: 5px 5px 0px 0px #333;
}

.contact h1 {
    padding-bottom: 25px;
}

.cont-text {
    text-align: center;
}

.cont-btu {
    text-align: center;
    margin: 50px 0 0 0;
}

.cont-btu a {
    text-decoration: none;
    color: #000;
    border: 1px solid #000;
    padding: 10px;
    transition: all 0.5s;
}

.cont-btu a:hover {
    opacity: 0.7;
    box-shadow: 5px 5px 0px 0px #333;
}

.contact {
    padding: 150px 20px 0;
}

.rule h1 {
    padding-bottom: 25px;
}

.rule-text {
    text-align: center;
}

.rule-btu {
    text-align: center;
    margin: 50px 0 0 0;
}

.rule-btu a {
    text-decoration: none;
    color: #000;
    border: 1px solid #000;
    padding: 10px;
    transition: all 0.5s;
}

.rule-btu a:hover {
    opacity: 0.7;
    box-shadow: 5px 5px 0px 0px #333;
}

.rule {
    padding: 150px 20px 0;
}