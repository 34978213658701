.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
}

.loading-screen img {
    width: 500px;
    height: auto;
}

.loading-screen p {
    font-size: 20px;
}

@media screen and (max-width: 959px) {
    .loading-screen img {
        width: 100%;
        height: auto;
    }
}